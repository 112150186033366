import CryptoJS from "crypto-js";

export class AesEncryption {
  constructor(key, salt) {
    this.key = key;
    this.salt = salt;
  }

  encrypt(inputText) {
    const plainText = JSON.stringify(inputText);
    const finalKey = this.key + this.salt;
    const encrypted = CryptoJS.AES.encrypt(plainText, finalKey).toString();
    const base64Encoded = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(encrypted)
    );
    return base64Encoded;
  }

  decrypt(inputText) {
    const base64Decoded = CryptoJS.enc.Base64.parse(inputText).toString(
      CryptoJS.enc.Utf8
    );
    const finalKey = this.key + this.salt;
    const decrytedObj = CryptoJS.AES.decrypt(base64Decoded, finalKey).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decrytedObj);
  }
}

// const keySalt = process.env.REACT_APP_ENCRYPTION_SALT;
