import { useContext, useEffect, useRef } from "react";
import { MapContext } from "./Map";
import Draw from "ol/interaction/Draw.js";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";

const DrawPolygon = ({ polygonCoords, setPolygonCoords }) => {
  const { map } = useContext(MapContext);

  const sourceRef = useRef(new VectorSource({ wrapX: false }));

  useEffect(() => {
    if (!map) return;

    const vector = new VectorLayer({
      source: sourceRef.current,
    });

    map.addLayer(vector);

    const draw = new Draw({
      source: sourceRef.current,
      type: "Polygon",
    });

    draw.on("drawstart", () => {
      sourceRef.current.clear();
    });

    map.addInteraction(draw);

    draw.on("drawend", (event) => {
      const geometry = event.feature.getGeometry();
      const coordinates = geometry.getCoordinates();

      setPolygonCoords(coordinates[0]);
    });

    return () => {
      if (map) {
        map.removeLayer(vector);
        map.removeInteraction(draw);
      }
    };
  }, [map]);

  useEffect(() => {
    if (polygonCoords === null) {
      sourceRef.current.clear();
    }
  }, [polygonCoords]);

  return null;
};

export default DrawPolygon;
