import React, { useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";

const CustomTooltip = ({ button, message }) => {
  const UpdatingPopover = React.forwardRef(
    ({ popper, children, show: _, ...props }, ref) => {
      useEffect(() => {
        popper.scheduleUpdate();
      }, [children, popper]);

      return (
        <Popover ref={ref} body {...props}>
          {children}
        </Popover>
      );
    }
  );

  UpdatingPopover.displayName = "UpdatingPopover";

  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <UpdatingPopover id="popover-contained">{message}</UpdatingPopover>
      }
    >
      {button}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
