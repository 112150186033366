import React from "react";
import Footer from "../../../utilis/footer/Footer";
import Titlebar from "../Titlebar";
import Encryption from "./Encryption";

const PrivateEncryption = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Titlebar title="Private Encoder and Decoder" />
      <Encryption />
      <Footer />
    </div>
  );
};

export default PrivateEncryption;
