export const introMsg = {
  greeting:
    "<b><span>Hi there, thanks for visiting my portfolio.</span><br /><span>Let's learn briefly about who I am !!!</span><br /><br />",
  about: [
    '<i class="text-success fas fa-crosshairs"></i> Computer Science (w/ Math Minor) Graduate with Honors <br />',
    '<i class="text-success fas fa-crosshairs"></i> Experienced Full-Stack Software Development Engineer <br />',
    '<i class="text-success fas fa-crosshairs"></i> As proud amazonian, bringing smile to our customers <br />',
    '<i class="text-success fas fa-crosshairs"></i> Technology enthusiast, learning and developing the best <br />',
    '<i class="text-success fas fa-crosshairs"></i> Multi-lingual who can blend anywhere <br /><br /></b> ',
  ],
  footer:
    'scroll down to learn more about me...<i class="down-sym text-success fas fa-chevron-down"></i>  ',
};

export const experiences = [
  {
    id: 0,
    company: "Amazon.com Services LLC",
    address: "Bellevue, WA 98004",
    position: "Software Development Engineer I",
    from: "April 2022",
    to: "Present",
    logo: "/assests/Amazon_icon.svg.png",
    description: [
      "Design, review and build cloud-based micro-services which supports large distributed system to solve unique business problems.",
      "Produce, review and ship robust, high-standard, production-level, and efficient codes in several programming languages such as Java, TypeScript, Python, etc.",
      "Actively services for issues, deep dive into the issues, and provide effective fix tp prevent the issue from happening again.",
      "Collaborate with experienced cross-disciplinary Amazonians such as applied scientists, data analysts, hardware engineers, etc. to bring innovative solutions to business problems that contribute to the growth of the company.",
    ],
  },
  {
    id: 1,
    company: "Triencon Services Inc.",
    address: "Arlington, TX 76011",
    position: "Software Development Engineer I",
    from: "May 2021",
    to: "Present",
    logo: "/assests/tsi_logo.png",
    description: [
      "Designed, developed, and maintained application codes under the supervision of senior developers.",
      "Designed and developed IT resources monitoring web app using ASP.Net and ReactJS.",
      "Developed and debugged custom PI Vision extensibility symbol using AngularJS for TSI’s client.",
      "Improvised the PI Vision extensibility symbol’s functionality after client feedback.",
      "Optimized TSI’s website to improve performance and reduce server costs using .Net with ReactJS.",
      "Mentor new Developer interns by guiding them to learn and master our tech stacks.",
    ],
  },
  {
    id: 2,
    company: "The University of Texas at Arlington",
    address: "Arlington, TX 76019",
    position: "Research Assistant",
    from: "May 2021",
    to: "August 2021",
    logo: "/assests/UTA_logo.png",
    description: [
      "Develop applications needed for the research and/or the lab.",
      "Wrote scripts to automate the data entering process for experiments.",
      "Help organize the experiments and the data collection process of experiments.",
    ],
  },
  {
    id: 3,
    company: "Aleks Omega LLC",
    address: "Cedar Hill, TX 75104",
    position: "Software Engineer Intern",
    from: "January 2021",
    to: "May 2021",
    logo: "/assests/aleks_logo.png",
    description: [
      "Developed a python application to be able to import, store, retrieve, and analyze large public bidding data in a local SQL database server.",
      "Developed the backend logic to have a graphical representation of the data in the application.",
      "Attended weekly meetings to report the progress and discussion the past and future milestones with the team members and stakeholders.",
    ],
  },
  {
    id: 4,
    company: "The University of Texas at Arlington",
    address: "Arlington, TX 76019",
    position: "Math Tutor",
    from: "November 2019",
    to: "January 2021",
    logo: "/assests/UTA_logo.png",
    description: [
      "Tutored students for university level math courses.",
      "Worked as Specialized Tutor to help students during live lectures and labs.",
      "Conducted breakout sessions to help students understand the math topics missed during the lectures.",
      "Observed and Evaluated students learning styles to provide better techniques of learning.",
      "Administered pre-test sessions to help students get ready for midterms, and finals.",
      "Proctored tests for several university math courses.",
    ],
  },
  {
    id: 5,
    company: "Dallas College North Lake Campus",
    address: "Irving, TX 75038",
    position: "Math Tutor",
    from: "July 2018",
    to: "July 2019",
    logo: "/assests/DC_logo.png",
    description: [
      "Tutored students for college level math courses.",
      "Observed and Evaluated students learning styles to provide better techniques of learning.",
      "Administered pre-test sessions to help students get ready for midterms, finals as well as standardized tests.",
      "Proctored tests conducted in the Math Learning Center.",
      "Attended monthly meetings of tutors, coordinator and supervisor.",
    ],
  },
];

export const education = [
  {
    id: 1,
    college: "The University of Texas at Arlington",
    from: "2019",
    to: "2021",
    degree: "Bachelor of Science",
    major: "Computer Science",
    minor: "Mathematics",
    Honors: true,
    logo: "/assests/UTA_logo.png",
    landmark: "/assests/uta_landmark.png",
  },
  {
    id: 2,
    college: "Dallas College North Lake Campus",
    from: "2018",
    to: "2019",
    degree: "Associate of Science",
    major: "Computer Science",
    Honors: false,
    logo: "/assests/DC_logo.png",
    landmark: "/assests/nlc_landmark.jpeg",
  },
];

export const projects = [
  {
    id: 1,
    title: "Life-fit App (Sponsored by State farm)",
    githubLink: "https://github.com/sushantcode/sd_lifefit",
    description:
      "Designed & develop a system (Mobile & Web Apps) that gets and stores user’s health data using Fitbit watch API and AWS services, and generate health score using Machine Learning technique that can be used to provide better health/life insurance rates.",
    techStack:
      "Java, Python, JavaScript, React, Flask, Bootstrap, AWS (Cognito, S3, DynamoDb, Lambda), Fitbit API",
  },
  {
    id: 2,
    title: "Self-Fin - A Personal Finance Management App",
    githubLink: "https://github.com/sushantcode/self-fin",
    description:
      "A responsive stand-alone web app to track and manage daily financial activities like expenses, income, savings, and investments developed providing special attention to data security given no-one wants others to track their personal financial activities.",
    techStack:
      "JavaScript, React, Python, Bootstrap 5, AWS DynamoDb, Firebase Hosting, Github, AES Cryptographic Algorithm",
  },
  {
    id: 3,
    title: "Maintenance Scheduler - A Web Application",
    githubLink: "https://github.com/sushantcode/MaintainceScheduler",
    description:
      "Developed a Java Spring microservice and a ReactJS based frontend to help manufacturing factories maintain their equipments' service record easy and systematic. App also provides role-based user authentication to prevent fraud and information leak.",
    techStack:
      "Java, Spring Boot, ReactJS, Docker, Kafka + Zookeeper, MySql, Swagger 2, Log4j2 Logger",
  },
  {
    id: 4,
    title: "Texas Hamburger - A Resturant Chain",
    githubLink: "https://github.com/sushantcode/TexasHamburger",
    description:
      "Developed microservices to handle day to day operations for an imaginary hamburger resturant chain. One can update store information, create new orders, reservation and stream data through Kafka to database and other microservices.",
    techStack:
      "Java, Spring Boot, Docker, Kafka + Zookeeper, MySql, Mongodb, Swagger 2, Log4j2 Logger",
  },
  {
    id: 5,
    title: "Adiuva - A Donation Platform",
    githubLink: "https://github.com/sushantcode/Adiuva",
    description:
      "Leaded a team to design, develop, and deploy a social media type web app using ReactJS as frontend, ExpressJS as backend, and Firebase for database, cloud functions, authentication, and deployment, aimed to help raised donation for victims affected by certain tragedies.",
    techStack: "ReactJS, HTML, CSS, MUI, Firebase, NodeJS, ExpressJS",
  },
  {
    id: 6,
    title: "Ayansh Clinic Website",
    githubLink: "https://github.com/sushantcode/AayanshClinic",
    description:
      "Designed, developed, and hosted a complete, user-friendly, and highly responsive website using ReactJS library and Firebase for a Clinic/Pharmacy in Nepal. This features a custom CMS for authorized admin to easily add, remove, and update the dynamic contents of the website.",
    techStack: "ReactJS, HTML, CSS, MUI, Firebase",
  },
  {
    id: 7,
    title: "BizNotio - An Android App",
    githubLink: "https://github.com/sushantcode/BizNotio",
    description:
      "Designed and developed an android application to enable users to share business ideas between investors and investee. We used Kotlin language for development, Firebase for database and authentication activities, Twilio for audio/video calling, and Google Pay API for payment processing.",
    techStack:
      "Android, Kotlin, Firebase, JetPack, Figma, Twilio, Google-Pay, IntelliJ Idea",
  },
  {
    id: 8,
    title: "Personal Portfololio - A React App",
    githubLink: "https://github.com/sushantcode/portfolio-sushant",
    description:
      "Designed and developed a personal portfolio website using ReactJS library. The website includes number of annimations and attractive modern CSS designs. Hosted it over firebase using Github Actions for CI/CD.",
    techStack:
      "ReactJS, HTML, SCSS, Bootstrap, Firebase, Github Actions - CI/CD, EmailJS, reCAPTCHA - Google",
  },
  {
    id: 9,
    title: "Text Blinker - A React Package",
    githubLink: "https://github.com/sushantcode/changing-text-blinker",
    description:
      "Developed and published a react component package that change content with every blink. It provides options to customize styles as well as randomness and blink time intervals.",
    techStack: "Javascript, Webpack, Babel, ReactJS, Storybook, HTML, CSS",
  },
  {
    id: 10,
    title: "ELSA Store - Online POS System",
    githubLink:
      "https://github.com/sushantcode/CPP_Programs_OOPs/tree/master/ELSA_Store_Operation",
    description:
      "ELSA, a POS system software developed in the structured object-oriented programming C++ using full GUI from gtkmm’s GTK library and C++ standard library. Developed over several sprints following the proper scrum chart to track its completion.",
    techStack: "C++, GTK+, gtkmm",
  },
];

export const skills = {
  expert: {
    programming_lang: {
      value: "Java, JavaScript, Python, HTML, CSS, SQL",
      logos: [
        "java.svg",
        "javascript.svg",
        "python.svg",
        "html5.svg",
        "css3.svg",
      ],
    },
    frameworks_env: {
      value:
        "ReactJS, Flask, ASP.NET, ASP.NET Core, Spring Boot, ExpressJS, NodeJS, JVM, BootStrap, Materials UI",
      logos: [
        "react.svg",
        "flask.svg",
        "spring-boot.svg",
        "nodejs.svg",
        "bootstrap.svg",
      ],
    },
    tools_tech: {
      value: [
        {
          id: 1,
          name: "Git",
          services: "Version Control, Actions - CI/CD",
        },
        {
          id: 2,
          name: "MS Azure",
          services: "DevOps, Application Services",
        },
        {
          id: 3,
          name: "AWS",
          services: "EC2, Cognito, Lambda, DynamoDB, RDS, S3, Amplify",
        },
      ],
      logos: ["github.svg", "microsoft.svg", "aws.svg"],
    },
    ide: {
      value: "Visual Studio, VS Code, IntelliJ Idea, Android Studio",
      logos: [
        "visual-studio.svg",
        "vscode.png",
        "intellij-idea.svg",
        "android-studio.svg",
      ],
    },
    os: {
      value: "Windows, iOS, Linux - Ubuntu, UNIX",
      logos: ["windows-11.svg", "apple.svg", "tux.svg"],
    },
    other: {
      value:
        "Docker, Jenkins, Kafka + Zookeeper, Firebase, Postman, MySQL, Figma, Jest-Testing",
      logos: [
        "docker.png",
        "jenkins.png",
        "kafka_zoo.svg",
        "firebase.png",
        "postman.svg",
        "mysql.png",
        "figma.svg",
      ],
    },
  },
};

export const staticValues = {
  resume_url:
    "https://firebasestorage.googleapis.com/v0/b/sushant-portfolio-b57cf.appspot.com/o/Resume_Sushant-02-19-2023.pdf?alt=media&token=a9f35e77-4b09-42e8-8311-1685fbf10983",
  contact: {
    email: "sushant.career23@gmail.com",
    linkedin: "https://www.linkedin.com/in/sgupta2016/",
    github: "https://github.com/sushantcode",
    location: "Seattle, Washington",
    phone: "+1 (206) 745-2696",
  },
  copy_right_year: "2023",
};
