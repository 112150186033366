import React from "react";
import "./Menu.scss";

export const Menu = ({ isOpen, setIsOpen }) => {
  return (
    <div className={`menu ${isOpen && "active"}`}>
      <div className="menu_content">
        <ul className="menu_list">
          <li className="menu_list-item">
            <a className="anchor" href="#home" onClick={() => setIsOpen(false)}>
              Home
            </a>
          </li>
          <li className="menu_list-item">
            <a
              className="anchor"
              href="#experience"
              onClick={() => setIsOpen(false)}
            >
              Experience
            </a>
          </li>
          <li className="menu_list-item">
            <a
              className="anchor"
              href="#projects"
              onClick={() => setIsOpen(false)}
            >
              Projects
            </a>
          </li>
          <li className="menu_list-item">
            <a
              className="anchor"
              href="#skills"
              onClick={() => setIsOpen(false)}
            >
              Skills
            </a>
          </li>
          <li className="menu_list-item">
            <a
              className="anchor"
              href="#education"
              onClick={() => setIsOpen(false)}
            >
              Education
            </a>
          </li>
          <li className="menu_list-item">
            <a
              className="anchor"
              href="#contact"
              onClick={() => setIsOpen(false)}
            >
              Contact
            </a>
          </li>
          <li className="menu_list-item">
            <a
              className="anchor"
              href="/encryption"
              onClick={() => setIsOpen(false)}
            >
              Encryption Tools
            </a>
          </li>
          <li className="menu_list-item">
            <a
              className="anchor"
              href="/geo-tooling"
              onClick={() => setIsOpen(false)}
            >
              Geospatial Tools
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
