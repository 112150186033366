import bcrypt from "bcryptjs";

export class BcryptEncryption {
  constructor(cost) {
    this.cost = cost;
  }

  hash(inputText) {
    if (this.cost < 5 || this.cost > 15) {
      throw new Error("Unsupported number of cost!!!");
    }
    const salt = bcrypt.genSaltSync(this.cost);
    const hashedValue = bcrypt.hashSync(inputText, salt);
    return hashedValue;
  }

  validate(inputText, hashedValue) {
    const isValid = bcrypt.compareSync(inputText, hashedValue);
    return isValid;
  }
}
