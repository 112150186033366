import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import PrivateEncryption from "./components/pages/encryption/private/index";
import PublicEncryption from "./components/pages/encryption/public/index";
import Portfolio from "./components/pages/portfolio/index";
import GeojsonTiler from "./components/pages/geoTooling/index";

const App = function () {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Portfolio />} />
        <Route path="/locker" element={<PrivateEncryption />} />
        <Route path="/encryption" element={<PublicEncryption />} />
        <Route path="/geo-tooling" element={<GeojsonTiler />} />
      </Routes>
    </Router>
  );
};

export default App;
