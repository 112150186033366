import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { staticValues } from "../../../data";

const Footer = () => {
  return (
    <footer className="bg-dark py-2 mt-auto">
      <Container>
        <Row>
          <Col className="container text-center text-light">
            <p className="mb-0">
              &copy; {staticValues.copy_right_year} Gupta, Sushant. All rights
              reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
