import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  convertBBoxToTileNumbers,
  validateLatitude,
  validateLongitude,
  validateZoom,
} from "../../utilis/geoutils/GeoUtils";

const BBoxnToTileFinder = ({ results, setResults }) => {
  const [longitudeMin, setLongitudeMin] = useState(0);
  const [latitudeMin, setLatitudeMin] = useState(0);
  const [longitudeMax, setLongitudeMax] = useState(0);
  const [latitudeMax, setLatitudeMax] = useState(0);
  const [zoom, setZoom] = useState(14);
  const [copied, setCopied] = useState(false);
  const [bbox, setBBox] = useState(null);
  const [parserError, setParserError] = useState(null);

  useEffect(() => {
    if (validateJsonInput(bbox)) {
      const parsedInput = JSON.parse(bbox);
      setLongitudeMin(parsedInput?.minLongitude);
      setLatitudeMin(parsedInput?.minLatitude);
      setLongitudeMax(parsedInput?.maxLongitude);
      setLatitudeMax(parsedInput?.maxLatitude);
    }
  }, [bbox]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const results = convertBBoxToTileNumbers(
      longitudeMin,
      latitudeMin,
      longitudeMax,
      latitudeMax,
      zoom
    );

    setResults(results);
  };

  const validateJsonInput = (inputString) => {
    try {
      if (!inputString) {
        setParserError("");
        return false;
      }

      const parsedInput = JSON.parse(inputString);
      setParserError("");
      return (
        validateLatitude(parsedInput?.minLatitude) &&
        validateLatitude(parsedInput?.maxLatitude) &&
        validateLongitude(parsedInput?.minLongitude) &&
        validateLongitude(parsedInput?.maxLongitude)
      );
    } catch (error) {
      setParserError(error.message);
      return false;
    }
  };

  const resetForm = (e) => {
    e.preventDefault();
    setLongitudeMin(0);
    setLatitudeMin(0);
    setLongitudeMax(0);
    setLatitudeMax(0);
    setZoom(14);
    setResults(null);
    setBBox(null);
  };

  return (
    <div>
      <Form className="mt-3" onSubmit={handleSubmit}>
        <Row>
          <Form.Group as={Col} className="mb-3">
            <InputGroup hasValidation>
              <InputGroup.Text id="longitudeMin">Longitude Min</InputGroup.Text>
              <FormControl
                required
                autoComplete="off"
                type="number"
                name="longitudeMin"
                value={longitudeMin}
                isInvalid={!validateLongitude(longitudeMin)}
                isValid={validateLongitude(longitudeMin)}
                onChange={(e) => {
                  setLongitudeMin(e.target.value);
                }}
                placeholder={0.0}
              />
              <Form.Control.Feedback type="invalid">
                *Must be between -180 and 180
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="mb-3">
            <InputGroup hasValidation>
              <InputGroup.Text id="latitudeMin">Latitude Min</InputGroup.Text>
              <FormControl
                required
                autoComplete="off"
                type="number"
                name="latitudeMin"
                value={latitudeMin}
                isInvalid={!validateLatitude(latitudeMin)}
                isValid={validateLatitude(latitudeMin)}
                onChange={(e) => {
                  setLatitudeMin(e.target.value);
                }}
                placeholder={0.0}
              />
              <Form.Control.Feedback type="invalid">
                *Must be between -90 and 90
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3">
            <InputGroup hasValidation>
              <InputGroup.Text id="longitudeMax">Longitude Max</InputGroup.Text>
              <FormControl
                required
                autoComplete="off"
                type="number"
                name="longitudeMax"
                value={longitudeMax}
                isInvalid={!validateLongitude(longitudeMax)}
                isValid={validateLongitude(longitudeMax)}
                onChange={(e) => {
                  setLongitudeMax(e.target.value);
                }}
                placeholder={0.0}
              />
              <Form.Control.Feedback type="invalid">
                *Must be between -180 and 180
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} className="mb-3">
            <InputGroup hasValidation>
              <InputGroup.Text id="latitudeMax">Latitude Max</InputGroup.Text>
              <FormControl
                required
                autoComplete="off"
                type="number"
                name="latitudeMax"
                value={latitudeMax}
                isInvalid={!validateLatitude(latitudeMax)}
                isValid={validateLatitude(latitudeMax)}
                onChange={(e) => {
                  setLatitudeMax(e.target.value);
                }}
                placeholder={0.0}
              />
              <Form.Control.Feedback type="invalid">
                *Must be between -90 and 90
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Form.Group as={Col} className="mb-3">
          <InputGroup hasValidation>
            <InputGroup.Text id="zoom">Zoom</InputGroup.Text>
            <FormControl
              required
              autoComplete="off"
              type="number"
              name="zoom"
              value={zoom}
              isInvalid={!validateZoom(zoom)}
              isValid={validateZoom(zoom)}
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              placeholder={14}
            />
            <Form.Control.Feedback type="invalid">
              *Must be between 0 and 23
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Row className="mb-3 fw-bold text-center">
          <Col>OR,</Col>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3">
            <InputGroup hasValidation>
              <FormControl
                autoComplete="off"
                type="text"
                name="bbox"
                isInvalid={parserError}
                value={bbox || ""}
                onChange={(e) => {
                  setBBox(e.target.value);
                }}
                placeholder="Paste or Type BBOX JSON string here..."
              />
              <Form.Control.Feedback type="invalid">
                {parserError}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" type="submit" className="">
              Submit
            </Button>
          </Col>
          {results && (
            <Col className="text-end">
              <Button
                className="me-2"
                style={{ cursor: "pointer", width: "8rem" }}
                disabled={results === null || copied}
                onClick={() => {
                  navigator.clipboard
                    .writeText(JSON.stringify(results, null, 4))
                    .then(() => setCopied(true));
                }}
              >
                {copied ? "Copied" : "Copy JSON"}
              </Button>
              <Button
                style={{ cursor: "pointer", width: "8rem" }}
                onClick={resetForm}
              >
                Clear
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default BBoxnToTileFinder;
