import React, { useEffect, useState } from "react";
import Menubar from "./Menubar";
import MapRenderer from "./BBoxFinder";
import { useLocation } from "react-router-dom";
import TileFinder from "./TileFinder";

const GeoTooling = () => {
  const [activeHash, setActiveHash] = useState("");

  const loc = useLocation();

  useEffect(() => {
    setActiveHash(loc.hash);
  }, [loc]);

  const getActiveComponnet = (activePath) => {
    switch (activePath) {
      case "#TileFinder":
        return <TileFinder />;

      case "#BBoxFinder":
        return <MapRenderer shapeType="BBox" />;

      case "#PolygonFinder":
        return <MapRenderer shapeType="Polygon" />;

      default:
        return <MapRenderer shapeType="BBox" />;
    }
  };

  return (
    <div>
      <Menubar />
      {getActiveComponnet(activeHash)}
    </div>
  );
};

export default GeoTooling;
