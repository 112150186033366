import React from "react";
import "./Titlebar.scss";

const Titlebar = function ({ isOpen, setIsOpen }) {
  return (
    <div className={`titlebar ${isOpen && "active"}`}>
      <div className="nav-wrapper">
        <div className="left">
          <a href="#home" className="logo-name">
            {"<Sushant Gupta />"}
          </a>
        </div>
        <div className="right">
          <div className="menu-btn" onClick={() => setIsOpen(!isOpen)}>
            <div className="hamburger-btn" />
            <div className="hamburger-btn" />
            <div className="hamburger-btn" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Titlebar;
