import React, { useState } from "react";
import CryptoJS from "crypto-js";

const keySalt = process.env.REACT_APP_ENCRYPTION_SALT;

const Encryption = () => {
  const [option, setOption] = useState("decrypt");
  const [userKey, setUserKey] = useState("");
  const [inputText, setInputText] = useState("");
  const [result, setResult] = useState("");
  const [copied, setCopied] = useState(false);

  const encryptText = () => {
    return CryptoJS.AES.encrypt(inputText, userKey + keySalt).toString();
  };

  const decryptText = () => {
    return CryptoJS.AES.decrypt(inputText, userKey + keySalt).toString(
      CryptoJS.enc.Utf8
    );
  };

  const resetInputFields = () => {
    setOption("decrypt");
    setUserKey("");
    setInputText("");
  };

  const onSubmit = () => {
    if (option === "encrypt") {
      setResult(encryptText());
    } else {
      setResult(decryptText());
    }
    resetInputFields();
  };

  return (
    <div className="container mt-2">
      <div className="row mt-2">
        <div className="col fs-5">
          <section>
            What you want to do?
            <div className="mt-3 ms-4 d-flex">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="options"
                  id="decrypt"
                  value="decrypt"
                  checked={option === "decrypt"}
                  onChange={(e) => setOption(e.target.value)}
                />
                <label className="form-check-label" htmlFor="decrypt">
                  Decryption
                </label>
              </div>
              <div className="form-check ms-4">
                <input
                  className="form-check-input"
                  type="radio"
                  name="options"
                  id="encrypt"
                  value="encrypt"
                  checked={option === "encrypt"}
                  onChange={(e) => setOption(e.target.value)}
                />
                <label className="form-check-label" htmlFor="encrypt">
                  Encryption
                </label>
              </div>
            </div>
          </section>
          <section className="mt-4">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="user_key">
                  Secret Key
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Encryption Key"
                aria-label="user_key"
                aria-describedby="user_key"
                value={userKey}
                onChange={(e) => setUserKey(e.target.value)}
              />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="input">
                  Text Input
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder={`Text to ${
                  option === "encrypt" ? "encrypt" : "decrypt"
                }`}
                aria-label="input"
                aria-describedby="input"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              />
            </div>
          </section>
          <section className="text-center">
            <button
              type="submit"
              className="btn btn-primary fs-5 fw-bold"
              onClick={() => onSubmit()}
            >
              {option === "decrypt" ? "Decrypt" : "Encrypt"}
            </button>
          </section>
          {result.length !== 0 && (
            <section className="mt-4 fs-4 text-center">
              <span className="border text-success px-2 pb-1">{result}</span>
              <span
                className="border px-3 pb-1"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(result);
                  setCopied(true);
                }}
              >
                <img
                  src={`assests/${
                    copied ? "clipboard-check.svg" : "clipboard.svg"
                  }`}
                  alt="clipboard"
                />
              </span>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default Encryption;
