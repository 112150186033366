import React from "react";

const SkillCard = ({ title, tagline, data, simpleText, logos }) => {
  return (
    <div data-aos="zoom-in-down" className="box col-11 col-md-8 col-lg-4 mb-5">
      <div className="body card p-0 h-100 text-center shadow">
        <div className="front">
          <h3 className="card-title fw-bold mb-4">{title}</h3>
          <div className="card-body">
            <div className="card-logos">
              {logos?.map((logo, ind) => {
                return (
                  // eslint-disable-next-line prefer-template
                  <img
                    src={"/assests/" + logo}
                    alt="logos"
                    style={{
                      height: "35px",
                      marginRight: "15px",
                      marginBottom: "10px",
                    }}
                    key={ind}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="content card-body d-flex flex-column align-items-center justify-content-center">
          {data ? (
            <div>
              <ul style={{ listStyle: "none" }} className="text-start">
                {data?.map((item, index) => {
                  return (
                    <li key={index}>
                      <p className="fs-6 text-white">
                        <span className="text-danger fw-bold fs-5">
                          {item.name}
                        </span>
                        {": "}
                        {item.services}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <div>
              <p className="fs-4 text-white">{simpleText}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SkillCard;
