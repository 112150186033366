import React from "react";
import "./Education.scss";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { education } from "../../../../data";

const Education = function () {
  const eduCards = education?.map((data) => {
    let logoStyleLeftMargin = "0";

    if (data.id === 1) {
      logoStyleLeftMargin = "-4px";
    } else if (data.id === 2) {
      logoStyleLeftMargin = "10px";
    }

    return (
      <VerticalTimelineElement
        key={data.id}
        className="vertical-timeline-element--work"
        contentStyle={{ background: "white", color: "black" }}
        // eslint-disable-next-line prefer-template
        date={data.from + " - " + data.to}
        dateClassName="date"
        iconStyle={{ background: "white", color: "#fff" }}
        icon={
          <img
            className="org_logo"
            src={data.logo}
            alt="Org logo"
            style={{
              marginLeft: logoStyleLeftMargin,
            }}
          />
        }
      >
        <h3 className="vertical-timeline-element-title">{data.college}</h3>
        <h5 className="vertical-timeline-element-subtitle">
          {data.degree}
          {data.Honors && " (Honors)"}
        </h5>
        <h6 className="vertical-timeline-element-subtitle">
          Major: {data.major}
        </h6>
        {data.minor && (
          <h6 className="vertical-timeline-element-subtitle">
            Minor: {data.minor}
          </h6>
        )}
        <div className="landmark_wrap d-flex justify-content-center mt-4">
          <img className="landmark_img" src={data.landmark} alt="Landmark" />
        </div>
      </VerticalTimelineElement>
    );
  });
  return (
    <div className="education" id="education">
      <div className="title_wrapper text-center pt-3 pb-3">
        <h3 className="title fw-bold text-light">Education</h3>
      </div>
      <div className="wrapper mt-5 mb-4">
        <VerticalTimeline
          className="edu_timeline"
          layout="2-columns"
          lineColor="white"
        >
          {eduCards}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Education;
