import React, { useEffect, useState } from "react";
import Map from "./map/Map";
import Layers from "./map/Layers";
import TileLayer from "./map/TilerLayer";
import Controls from "./map/Controls";
import OSM from "ol/source/OSM.js";
import DrawBBox from "./map/DrawBBox";
import { Button, Col, Container, Row } from "react-bootstrap";
import GeocoderService from "./map/GeocoderService";
import Table from "react-bootstrap/Table";
import DrawPolygon from "./map/DrawPolygon";

const MapRenderer = ({ shapeType }) => {
  const [boxCoords, setBoxCoords] = useState(null);
  const [polygonCoords, setPolygonCoords] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setCopied(false);
  }, [boxCoords]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Map center={[-122.15587331669768, 47.625654071555296]} zoom={10}>
            <Layers>
              <TileLayer source={new OSM()} zIndex={0} />
              {shapeType === "Polygon" ? (
                <DrawPolygon
                  polygonCoords={polygonCoords}
                  setPolygonCoords={setPolygonCoords}
                />
              ) : (
                <DrawBBox boxCoords={boxCoords} setBoxCoords={setBoxCoords} />
              )}
            </Layers>
            <Controls>
              <GeocoderService />
            </Controls>
          </Map>
        </Col>
      </Row>
      <Row className="justify-content-md-center mx-1">
        <Col>
          <Table striped bordered hover className="my-auto">
            <thead>
              {shapeType === "Polygon" ? (
                <tr>
                  <th>Coordinates</th>
                </tr>
              ) : (
                <tr>
                  <th>Min-Longitude</th>
                  <th>Min-Latitude</th>
                  <th>Max-Longitude</th>
                  <th>Max-Latitude</th>
                </tr>
              )}
            </thead>
            <tbody>
              {shapeType === "Polygon" ? (
                <tr>
                  <td>
                    {polygonCoords ? JSON.stringify(polygonCoords) : "N/A"}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>{boxCoords ? boxCoords.minLongitude : "N/A"}</td>
                  <td>{boxCoords ? boxCoords.minLatitude : "N/A"}</td>
                  <td>{boxCoords ? boxCoords.maxLongitude : "N/A"}</td>
                  <td>{boxCoords ? boxCoords.maxLatitude : "N/A"}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col md="auto" className="my-auto fw-bold">
          <Row className="mb-1">
            <Button
              style={{ cursor: "pointer", width: "8rem" }}
              disabled={
                (shapeType === "Polygon"
                  ? polygonCoords === null
                  : boxCoords === null) || copied
              }
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    shapeType === "Polygon"
                      ? JSON.stringify(polygonCoords, null, 2)
                      : JSON.stringify(boxCoords, null, 2)
                  )
                  .then(() => setCopied(true));
              }}
            >
              {copied ? "Copied" : "Copy JSON"}
            </Button>
          </Row>
          <Row>
            <Button
              style={{ cursor: "pointer", width: "8rem" }}
              disabled={
                shapeType === "Polygon"
                  ? polygonCoords === null
                  : boxCoords === null
              }
              onClick={() => {
                setBoxCoords(null);
                setPolygonCoords(null);
              }}
            >
              Clear
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MapRenderer;
