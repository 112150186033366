export const validateLongitude = (longitude) => {
  return longitude >= -180.0 && longitude <= 180.0;
};

export const validateLatitude = (latitude) => {
  return latitude >= -90.0 && latitude <= 90.0;
};

export const validateZoom = (zoom) => {
  return zoom >= 0 && zoom <= 23;
};

export const convertDegreeToTile = (longitude, latitude, zoom) => {
  const lat_rad = (latitude * Math.PI) / 180;
  const n = 1 << zoom;
  let xtile = Math.floor(((longitude + 180.0) / 360.0) * n);
  let ytile = Math.floor(
    ((1.0 - Math.log(Math.tan(lat_rad) + 1 / Math.cos(lat_rad)) / Math.PI) /
      2.0) *
      n
  );

  if (xtile < 0) {
    xtile = 0;
  }

  if (xtile >= n) {
    xtile = n - 1;
  }

  if (ytile < 0) {
    ytile = 0;
  }

  if (ytile >= n) {
    ytile = n - 1;
  }

  return { xtile, ytile };
};

export const convertBBoxToTileNumbers = (
  minLon,
  minLat,
  maxLon,
  maxLat,
  zoom
) => {
  const minTile = convertDegreeToTile(minLon, maxLat, zoom);
  const maxTile = convertDegreeToTile(maxLon, minLat, zoom);

  const xtiles = Array.from(
    { length: maxTile.xtile - minTile.xtile + 1 },
    (_, i) => minTile.xtile + i
  );

  const ytiles = Array.from(
    { length: maxTile.ytile - minTile.ytile + 1 },
    (_, i) => minTile.ytile + i
  );

  return Array.from({ length: xtiles.length * ytiles.length }, (_, i) => ({
    xtile: xtiles[Math.floor(i / ytiles.length)],
    ytile: ytiles[i % ytiles.length],
  }));
};
