import React, { useState } from "react";
import "./Contact.scss";
import ReCaptchaV2 from "react-google-recaptcha";
import { staticValues } from "../../../../data";

const defaultErrorObj = {
  status: false,
  value: "",
};

const templateId = process.env.REACT_APP_TEMPLATE_ID;
const serviceId = process.env.REACT_APP_SERVICE_ID;

const Contact = function () {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [emailErr, setEmailErr] = useState(defaultErrorObj);
  const [nameErr, setNameErr] = useState(defaultErrorObj);
  const [phoneErr, setPhoneErr] = useState(defaultErrorObj);
  const [messageErr, setMessageErr] = useState(defaultErrorObj);
  const [recaptchaErr, setRecaptchaErr] = useState(false);
  const [submissionResult, setSubmissionResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaComplete, setRecaptchaComplete] = useState(false);

  const onChangeName = (event) => {
    setName(event.target.value);
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onChangePhone = (event) => {
    setPhone(event.target.value);
  };

  const onChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const validateEmail = () => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateData = () => {
    let errorFound = false;
    if (name.length === 0) {
      errorFound = true;
      setNameErr({
        status: true,
        value: "Name is required",
      });
    } else {
      setNameErr(defaultErrorObj);
    }
    if (email.length === 0) {
      errorFound = true;
      setEmailErr({
        status: true,
        value: "Email is required",
      });
    } else if (!validateEmail()) {
      errorFound = true;
      setEmailErr({
        status: true,
        value: "Please enter valid email",
      });
    } else {
      setEmailErr(defaultErrorObj);
    }
    if (phone.length !== 0 && phone.length !== 10) {
      errorFound = true;
      setPhoneErr({
        status: true,
        value: "Please enter valid phone number",
      });
    } else {
      setPhoneErr(defaultErrorObj);
    }
    if (message.length === 0) {
      errorFound = true;
      setMessageErr({
        status: true,
        value: "Please say something here",
      });
    } else {
      setMessageErr(defaultErrorObj);
    }
    if (recaptchaComplete) {
      setRecaptchaErr(false);
    } else {
      setRecaptchaErr(true);
    }

    return !errorFound;
  };

  const handleReCaptchaChange = (token) => {
    setRecaptchaComplete(true);
  };

  const handleReCaptchaExpire = () => {
    setRecaptchaComplete(false);
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setNameErr(defaultErrorObj);
    setEmailErr(defaultErrorObj);
    setPhoneErr(defaultErrorObj);
    setMessageErr(defaultErrorObj);
  };

  const sendEmail = (formData) => {
    window.emailjs
      .send(serviceId, templateId, formData)
      .then((res) => {
        // eslint-disable-next-line
        console.log("Email successfully sent!");
        resetForm();
        setIsLoading(false);
        setSubmissionResult({
          success: true,
          message: "Message sent successfully",
        });
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.error("Error occured:", err);
        resetForm();
        setIsLoading(false);
        setSubmissionResult({
          success: false,
          message: "Sorry, could not send the message. Please, try again!",
        });
      });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (validateData() && recaptchaComplete) {
      const data = {
        from_name: name,
        to_name: phone,
        message,
        reply_to: email,
      };
      setIsLoading(true);
      sendEmail(data);
    }
  };
  return (
    <div className="contact" id="contact">
      <div className="container wrapper">
        <div className="title_wrapper text-center pb-3">
          <h3 className="title fw-bold text-light">Contact Me</h3>
        </div>
        <div className="row mt-4">
          <div className="col-md-6 d-flex justify-content-center">
            <div className="contact_form_wrapper">
              <form className="row" onSubmit={onSubmitHandler}>
                <div className="col-12 form-element mb-4">
                  <label htmlFor="fullname" className="form-label">
                    Full Name*
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      nameErr.status ? "is-invalid" : ""
                    }`}
                    id="fullname"
                    value={name}
                    placeholder="John Doe"
                    onChange={onChangeName}
                  />
                  <div className="invalid-feedback">{nameErr.value}</div>
                </div>
                <div className="col-12 form-element mb-4">
                  <label htmlFor="email" className="form-label">
                    Email address*
                  </label>
                  <input
                    type="email"
                    className={`form-control ${
                      emailErr.status ? "is-invalid" : ""
                    }`}
                    id="email"
                    value={email}
                    placeholder="xyz@example.com"
                    onChange={onChangeEmail}
                  />
                  <div className="invalid-feedback">{emailErr.value}</div>
                </div>
                <div className="col-12 form-element mb-4">
                  <label htmlFor="phone" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="number"
                    className={`form-control ${
                      phoneErr.status ? "is-invalid" : ""
                    }`}
                    id="phone"
                    value={phone}
                    placeholder="0123456789"
                    onChange={onChangePhone}
                  />
                  <div className="invalid-feedback">{phoneErr.value}</div>
                </div>
                <div className="col-12 form-element mb-4">
                  <label htmlFor="message" className="form-label">
                    Message*
                  </label>
                  <textarea
                    className={`form-control ${
                      messageErr.status ? "is-invalid" : ""
                    }`}
                    id="message"
                    rows="3"
                    value={message}
                    placeholder="Type your message here"
                    onChange={onChangeMessage}
                  />
                  <div className="invalid-feedback">{messageErr.value}</div>
                </div>
                <div className="col-12 text-center">
                  <div className="row">
                    <div className="col-lg-8">
                      <ReCaptchaV2
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={handleReCaptchaChange}
                        onExpired={handleReCaptchaExpire}
                      />
                      {recaptchaErr && (
                        <div className="text-danger">*Complete reCaptcha</div>
                      )}
                    </div>
                    <div className="col-lg-4 mt-4">
                      <button
                        type="submit"
                        disabled={isLoading}
                        className="btn btn-primary fw-bold fs-5 px-4"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  {submissionResult !== null && (
                    <div
                      className={
                        submissionResult.success
                          ? "valid-feedback mt-2"
                          : "invalid-feedback mt-2"
                      }
                      style={{
                        display: "block",
                        fontSize: "1.1em",
                      }}
                    >
                      {submissionResult.message}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact_link_wrapper align-self-center text-light">
              <div className="email_wrapper d-flex justify-content-center mb-3">
                <h5 className="contact_email">
                  <i className="fas fa-at" />{" "}
                  <a
                    href={`mailto:${staticValues.contact.email}`}
                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {staticValues.contact.email}
                  </a>
                </h5>
              </div>
              <div className="phone_wrapper d-flex justify-content-center mb-3">
                <h5 className="contact_phone">
                  <i className="fas fa-phone-alt" />{" "}
                  <a
                    href="tel:6825142338"
                    style={{
                      textDecoration: "none",
                      color: "white",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {staticValues.contact.phone}
                  </a>
                </h5>
              </div>
              <div className="address_wrapper d-flex justify-content-center">
                <h5 className="contact_address">
                  <i className="fas fa-thumbtack" />{" "}
                  {staticValues.contact.location}
                </h5>
              </div>
              <div className="social_medias d-flex justify-content-center mt-4">
                <h3 className="linkedin_logo me-4">
                  <a
                    href={staticValues.contact.linkedin}
                    target="_blank"
                    rel="noreferrer"
                    className="social_logo"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </h3>
                <h3 className="github_logo">
                  <a
                    href={staticValues.contact.github}
                    className="social_logo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-github-square" />
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
