import React, { useState } from "react";
import Titlebar from "./titlebar/Titlebar";
import { Menu } from "./menu/Menu";
import Home from "./home/Home";
import Experience from "./experience/Experience";
import Skills from "./skills/Skills";
import Project from "./projects/Project";
import Education from "./education/Education";
import Contact from "./contact/Contact";
import Footer from "../../utilis/footer/Footer";

const Portfolio = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="app">
      <video className="bg-video" autoPlay muted loop>
        <source src="assests/Home_Background.mp4" type="video/mp4" />
      </video>
      <div className="header">
        <Titlebar isOpen={isOpen} setIsOpen={setIsOpen} />
        <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div className="contents container-fluid min-vh-100">
        <Home />
        <Experience />
        <Skills />
        <Project />
        <Education />
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

export default Portfolio;
