import {
  faCircleInfo,
  faFileText,
  faHashtag,
  faListNumeric,
  faLock,
  faLockOpen,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import CustomTooltip from "../../../utilis/customTooltip/CustomTooltip";
import { BcryptEncryption } from "../../../utilis/encryption/BcryptEncryption";

const BcryptHandler = () => {
  const [rounds, setRounds] = useState("10");
  const [hashed, setHashed] = useState("");
  const [plainText, setPlainText] = useState("");
  const [operation, setOperation] = useState("encrypt");
  const [output, setOutput] = useState(null);
  const [copied, setCopied] = useState(false);
  const [isSaltRoundValid, setIsSaltRoundValid] = useState(true);

  useEffect(() => {
    setOutput(null);
  }, [operation]);

  const validateSaltRounds = (saltRounds) => {
    if (saltRounds < 5 || saltRounds > 15) {
      return false;
    }
    return true;
  };

  const onSubmit = (e) => {
    const bcryptor = new BcryptEncryption(Number(rounds));

    e.preventDefault();
    if (operation === "encrypt") {
      const encrypted = bcryptor.hash(plainText);
      setOutput({
        inputText: plainText,
        rounds: rounds,
        encrypted: encrypted,
      });
    } else {
      const isValid = bcryptor.validate(plainText, hashed);
      setOutput({
        inputText: plainText,
        encrypted: hashed,
        validation: isValid,
      });
    }

    resetForm();
  };

  const resetForm = () => {
    setPlainText("");
    setRounds(10);
    setHashed("");
  };

  return (
    <Row className="mt-3">
      <Col>
        <Card>
          <Card.Header className="text-center fs-4">
            Provide given values for Bcrypt Encryption?
          </Card.Header>
          <Card.Body>
            <Tabs
              defaultActiveKey="encrypt"
              id="controlled-tab"
              className="mb-3"
              activeKey={operation}
              onSelect={(key) => setOperation(key)}
            >
              <Tab eventKey="encrypt" title="Encrypt" />
              <Tab eventKey="validate" title="Validate" />
            </Tabs>
            <Form className="mt-3">
              <Form.Group as={Col} className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faFileText} className="me-2" />{" "}
                    Plain-Text
                  </InputGroup.Text>
                  <FormControl
                    required
                    autoComplete="off"
                    type="text"
                    name="plain-text"
                    value={plainText}
                    onChange={(e) => setPlainText(e.target.value)}
                    placeholder={
                      operation === "encrypt"
                        ? "Enter plain-text to encrypt"
                        : "Enter plain-text to validate against"
                    }
                  />
                </InputGroup>
                {plainText.length === 0 && (
                  <Form.Text className="ms-2" muted>
                    <span className="text-danger"> *Must enter a text </span>
                  </Form.Text>
                )}
              </Form.Group>
              {operation === "encrypt" ? (
                <Form.Group as={Col} className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faListNumeric} className="me-2" />{" "}
                      Salt Rounds (Optional, default 10)
                    </InputGroup.Text>
                    <FormControl
                      required
                      autoComplete="off"
                      type="number"
                      name="rounds"
                      value={rounds}
                      onChange={(e) => {
                        const inputRound = e.target.value;
                        setRounds(inputRound);
                        setIsSaltRoundValid(
                          validateSaltRounds(Number(inputRound))
                        );
                      }}
                      placeholder={10}
                    />
                    <CustomTooltip
                      button={
                        <FontAwesomeIcon icon={faCircleInfo} className="ms-2" />
                      }
                      message="Provide number of rounds used for salt generation which is then used for hashing hashing. \
                      The greater the rounds, the more time it needs to hash the password. \
                      So to avoid large computation, we are restricting the rounds value with range 5-15, with 10 being a default value."
                    />
                  </InputGroup>
                  {!isSaltRoundValid && (
                    <Form.Text className="ms-2" muted>
                      <span className="text-danger">
                        {" "}
                        *Must be in range 5-15
                      </span>
                    </Form.Text>
                  )}
                </Form.Group>
              ) : (
                <Form.Group as={Col} className="mb-3">
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faHashtag} className="me-2" />{" "}
                      Hashed/Encrypted Text
                    </InputGroup.Text>
                    <FormControl
                      required
                      autoComplete="off"
                      type="text"
                      name="hashed"
                      value={hashed}
                      onChange={(e) => setHashed(e.target.value)}
                      placeholder="$2a$12$R9h/cIPz0gi.URNNX3kh2OPST9/PgBkqquzi.Ss7KIUgO2t0jWMUW"
                    />
                  </InputGroup>
                  {hashed.length === 0 && (
                    <Form.Text className="ms-2" muted>
                      <span className="text-danger">
                        {" "}
                        *Must enter the hashed value{" "}
                      </span>
                    </Form.Text>
                  )}
                </Form.Group>
              )}
              <input type="submit" style={{ display: "none" }} disabled />
            </Form>
          </Card.Body>
          <Card.Footer className="py-3" style={{ textAlign: "right" }}>
            {/* {uploading && <div className="me-3 spinner-border" role="status" />} */}
            <Button
              className="me-3"
              size="sm"
              type="button"
              variant="success"
              onClick={(e) => onSubmit(e)}
              disabled={
                plainText.length === 0 ||
                (operation === "decrypt" && hashed.length === 0)
              }
            >
              <FontAwesomeIcon
                icon={operation === "encrypt" ? faLock : faLockOpen}
              />{" "}
              {operation === "encrypt" ? "Encrypt" : "Validate"}
            </Button>
            <Button
              size="sm"
              type="button"
              variant="info"
              onClick={() => resetForm()}
              disabled={plainText.length === 0 && hashed.length === 0}
            >
              <FontAwesomeIcon icon={faUndo} /> Reset
            </Button>
          </Card.Footer>
        </Card>
        {output && (
          <Card className="mt-3">
            <Card.Header className="text-center fs-5">Result</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group as={Col} className="mb-3">
                  <InputGroup>
                    <InputGroup.Text> Input Text </InputGroup.Text>
                    <FormControl
                      required
                      autoComplete="off"
                      type="text"
                      name="plain-text-dispaly"
                      value={output.inputText}
                      disabled
                    />
                  </InputGroup>
                </Form.Group>
                {operation === "encrypt" && (
                  <Form.Group as={Col} className="mb-3">
                    <InputGroup>
                      <InputGroup.Text> Salt Rounds </InputGroup.Text>
                      <FormControl
                        required
                        autoComplete="off"
                        type="number"
                        name="rounds-dispaly"
                        value={output.rounds}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                )}
                <Form.Group as={Col} className="mb-3">
                  <InputGroup>
                    <InputGroup.Text> Hashed/Encrypted </InputGroup.Text>
                    <FormControl
                      required
                      autoComplete="off"
                      type="text"
                      name="hashed-dispaly"
                      value={output.encrypted}
                      disabled
                    />
                    {operation === "encrypt" && (
                      <span
                        className="border px-3 pb-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(output.encrypted);
                          setCopied(true);
                        }}
                      >
                        <img
                          src={`assests/${
                            copied ? "clipboard-check.svg" : "clipboard.svg"
                          }`}
                          alt="clipboard"
                        />
                      </span>
                    )}
                  </InputGroup>
                </Form.Group>
                {operation === "validate" && (
                  <Form.Group as={Col} className="mb-3">
                    <InputGroup>
                      <InputGroup.Text> Validation </InputGroup.Text>
                      <FormControl
                        required
                        autoComplete="off"
                        type="text"
                        name="validation-dispaly"
                        className={
                          output.validation
                            ? "text-success fw-bold"
                            : "text-danger fw-bold"
                        }
                        value={output.validation ? "Matched" : "Unmatched"}
                        disabled
                      />
                    </InputGroup>
                  </Form.Group>
                )}
              </Form>
            </Card.Body>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default BcryptHandler;
