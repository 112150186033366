import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  convertDegreeToTile,
  validateLatitude,
  validateLongitude,
  validateZoom,
} from "../../utilis/geoutils/GeoUtils";
import "./GeoTooling.scss";

const PointToTileFinder = ({ results, setResults }) => {
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [zoom, setZoom] = useState(14);
  const [copied, setCopied] = useState(false);
  const [coordinatesInput, setCoordinatesInput] = useState("");
  const [parserError, setParserError] = useState(null);

  useEffect(() => {
    setParserError("");
    if (coordinatesInput) {
      const splittedInput = coordinatesInput.split(",");

      if (splittedInput.length > 0) {
        const latStr = splittedInput[0].trim();
        if (!isNaN(latStr)) {
          if (validateLatitude(latStr)) {
            setLatitude(Number(latStr));
          } else {
            setParserError("Latitude must be between -90 and 90");
          }
        } else {
          setParserError("Latitude must be a number");
        }
      }

      if (splittedInput.length > 1) {
        const lonStr = splittedInput[1].trim();
        if (!isNaN(lonStr)) {
          if (validateLongitude(lonStr)) {
            setLongitude(Number(lonStr));
          } else {
            setParserError("Longitude must be between -180 and 180");
          }
        } else {
          setParserError("Longitude must be a number");
        }
      }
    }
  }, [coordinatesInput]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setResults(convertDegreeToTile(longitude, latitude, zoom));
  };

  const resetForm = (e) => {
    e.preventDefault();
    setLatitude(0);
    setLongitude(0);
    setParserError("");
    setCoordinatesInput(null);
    setResults(null);
  };

  return (
    <div>
      <Form className="mt-3" onSubmit={handleSubmit}>
        <Form.Group as={Col} className="mb-3">
          <InputGroup hasValidation>
            <InputGroup.Text id="longitude">Longitude</InputGroup.Text>
            <FormControl
              required
              autoComplete="off"
              type="number"
              name="longitude"
              value={longitude}
              isInvalid={!validateLongitude(longitude)}
              isValid={validateLongitude(longitude)}
              onChange={(e) => {
                setLongitude(e.target.value);
              }}
              placeholder={0.0}
            />
            <Form.Control.Feedback type="invalid">
              *Must be between -180 and 180
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <InputGroup hasValidation>
            <InputGroup.Text id="latitude">Latitude</InputGroup.Text>
            <FormControl
              required
              autoComplete="off"
              type="number"
              name="latitude"
              value={latitude}
              isInvalid={!validateLatitude(latitude)}
              isValid={validateLatitude(latitude)}
              onChange={(e) => {
                setLatitude(e.target.value);
              }}
              placeholder={0.0}
            />
            <Form.Control.Feedback type="invalid">
              *Must be between -90 and 90
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group as={Col} className="mb-3">
          <InputGroup hasValidation>
            <InputGroup.Text id="zoom">Zoom</InputGroup.Text>
            <FormControl
              style={{ WebkitAppearance: "none", MozAppearance: "textfield" }}
              required
              autoComplete="off"
              type="number"
              name="zoom"
              value={zoom}
              isInvalid={!validateZoom(zoom)}
              isValid={validateZoom(zoom)}
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              placeholder={14}
            />
            <Form.Control.Feedback type="invalid">
              *Must be between 0 and 23
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Row className="mb-3 fw-bold text-center">
          <Col>OR,</Col>
        </Row>
        <Row>
          <Form.Group as={Col} className="mb-3">
            <InputGroup hasValidation>
              <FormControl
                autoComplete="off"
                type="text"
                name="coordinatesInput"
                isInvalid={parserError}
                value={coordinatesInput || ""}
                onChange={(e) => {
                  setCoordinatesInput(e.target.value);
                }}
                placeholder="Type Latitude, Longitude..."
              />
              <Form.Control.Feedback type="invalid">
                {parserError}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" type="submit" className="">
              Submit
            </Button>
          </Col>
          {results && (
            <Col className="text-end">
              <Button
                className="me-2"
                style={{ cursor: "pointer", width: "8rem" }}
                disabled={results === null || copied}
                onClick={() => {
                  navigator.clipboard
                    .writeText(JSON.stringify(results, null, 4))
                    .then(() => setCopied(true));
                }}
              >
                {copied ? "Copied" : "Copy JSON"}
              </Button>
              <Button
                style={{ cursor: "pointer", width: "8rem" }}
                onClick={resetForm}
              >
                Clear
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default PointToTileFinder;
