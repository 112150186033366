import React, { useEffect } from "react";
import "./Projects.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { projects } from "../../../../data";

const Project = function () {
  useEffect(() => {
    Aos.init({
      offset: 100,
      easing: "ease-in-sine",
    });
  }, []);
  const projectCards = projects?.map((data) => {
    return (
      <div
        data-aos="zoom-in-up"
        className="card_wrapper col-11 col-md-6 col-lg-6 mb-5"
        key={data.id}
      >
        <div className="card p-0 mx-2 h-100 overflow-hidden text-center shadow">
          <div className="card-header fw-bold fs-5">{data.title}</div>
          <div className="card-body">
            <p className="card-text mb-4 mt-3">{data.description}</p>
            <h6 className="card-title fw-bold my-4">
              {"[ "}
              {data.techStack}
              {" ]"}
            </h6>
            <a
              href={data.githubLink}
              className="btn btn-dark mb-3"
              target="_blank"
              rel="noreferrer"
            >
              Github
            </a>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="projects" id="projects">
      <div className="title_wrapper text-center pt-3 pb-3">
        <h3 className="title fw-bold text-light">Projects</h3>
      </div>
      <div className="py-4 py-lg-5 container cards_group_wrapper">
        <div className="row cards_group_row justify-content-center">
          {projectCards}
        </div>
      </div>
    </div>
  );
};

export default Project;
