import {
  faCircleInfo,
  faClipboard,
  faClipboardCheck,
  faFileText,
  faKey,
  faLock,
  faLockOpen,
  faPlusCircle,
  faShuffle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import CustomTooltip from "../../../utilis/customTooltip/CustomTooltip";
import { AesEncryption } from "../../../utilis/encryption/AesEncryption";

const AesHandler = () => {
  const [salt, setSalt] = useState("");
  const [key, setKey] = useState("");
  const [inputs, setInputs] = useState([""]);
  const [operation, setOperation] = useState("encrypt");
  const [output, setOutput] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    setOutput(null);
  }, [operation]);

  const performEncryption = (encryptor) => {
    const encryptedOutput = inputs
      .filter((item) => item.length !== 0)
      .map((item) => {
        const encrypted = encryptor.encrypt(item);
        return {
          input: item,
          result: encrypted,
          copied: false,
        };
      });

    setOutput({
      salt: salt,
      items: encryptedOutput,
      copied: false,
    });
  };

  const performDecryption = (encryptor) => {
    const decryptedOutput = inputs
      .filter((item) => item.length !== 0)
      .map((item) => {
        const decrypted = encryptor.decrypt(item);
        return {
          input: item,
          result: decrypted,
        };
      });

    setOutput({
      salt: salt,
      items: decryptedOutput,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setError("");
    const encryptor = new AesEncryption(key, salt);
    try {
      if (operation === "encrypt") {
        performEncryption(encryptor);
      } else {
        performDecryption(encryptor);
      }
    } catch (err) {
      setError(err.message);
    }

    resetForm();
  };

  const resetForm = () => {
    setInputs([""]);
    setSalt("");
    setKey("");
  };

  return (
    <Row className="mt-3">
      <Col>
        <Card>
          <Card.Header className="text-center fs-4">
            Provide given values for AES Encryption?
          </Card.Header>
          <Card.Body>
            <Tabs
              defaultActiveKey="encrypt"
              id="controlled-tab"
              className="mb-3"
              activeKey={operation}
              onSelect={(key) => setOperation(key)}
            >
              <Tab eventKey="encrypt" title="Encrypt" />
              <Tab eventKey="decrypt" title="Decrypt" />
            </Tabs>
            <Form className="mt-3">
              <Form.Group as={Col} className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faShuffle} className="me-2" />
                    Salt (Optional)
                  </InputGroup.Text>
                  <FormControl
                    required
                    autoComplete="off"
                    type="text"
                    name="salt"
                    value={salt}
                    onChange={(e) => setSalt(e.target.value)}
                    placeholder="Enter salt value"
                  />
                  <CustomTooltip
                    button={
                      <FontAwesomeIcon icon={faCircleInfo} className="ms-2" />
                    }
                    message="Provide random string values that you want to be added to your decrypted value. You can leave this blank if not want to use this salt."
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faKey} className="me-2" />{" "}
                    Key/Password
                  </InputGroup.Text>
                  <FormControl
                    required
                    autoComplete="off"
                    type="password"
                    name="key"
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                    placeholder="*********"
                  />
                </InputGroup>
                {key.length === 0 && (
                  <Form.Text className="ms-2" muted>
                    <span className="text-danger">*Must enter a key</span>
                  </Form.Text>
                )}
              </Form.Group>
              {inputs.map((element, index) => (
                <Form.Group as={Col} className="mb-3" key={index}>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faFileText} className="me-2" />{" "}
                      {`Input Text ${index + 1}`}
                    </InputGroup.Text>
                    <FormControl
                      required
                      autoComplete="off"
                      type="text"
                      name="input_text"
                      value={element}
                      onChange={(e) => {
                        const newInputs = [...inputs];
                        newInputs[index] = e.target.value;
                        setInputs(newInputs);
                      }}
                      placeholder={`Provide text for ${
                        operation === "encrypt" ? "encryption" : "decryption"
                      }`}
                    />
                  </InputGroup>
                </Form.Group>
              ))}
              <InputGroup className="text-center">
                <Button
                  onClick={(e) => {
                    const newInputs = [...inputs];
                    newInputs.push("");
                    setInputs(newInputs);
                  }}
                >
                  <FontAwesomeIcon icon={faPlusCircle} className="me-2" /> Add
                  Input Fields
                </Button>
              </InputGroup>
              <input type="submit" style={{ display: "none" }} disabled />
            </Form>
          </Card.Body>
          <Card.Footer className="py-3" style={{ textAlign: "right" }}>
            {/* {uploading && <div className="me-3 spinner-border" role="status" />} */}
            <Button
              className="me-3"
              size="sm"
              type="button"
              variant={operation === "encrypt" ? "danger" : "success"}
              onClick={(e) => onSubmit(e)}
              disabled={
                key.length === 0 ||
                (inputs.length === 1 && inputs[0].length === 0)
              }
            >
              <FontAwesomeIcon
                icon={operation === "encrypt" ? faLock : faLockOpen}
              />{" "}
              {operation === "encrypt" ? "Encrypt" : "Decrypt"}
            </Button>
            <Button
              size="sm"
              type="button"
              variant="info"
              onClick={() => resetForm()}
              disabled={
                key.length === 0 &&
                inputs.length === 1 &&
                inputs[0].length === 0
              }
            >
              <FontAwesomeIcon icon={faUndo} /> Reset
            </Button>
          </Card.Footer>
        </Card>
        {output && (
          <Card className="mt-3">
            <Card.Header className="text-center fs-5">Result</Card.Header>
            <Card.Body>
              <Form>
                <Form.Group as={Col} className="mb-3">
                  <InputGroup>
                    <InputGroup.Text> User Salt </InputGroup.Text>
                    <FormControl
                      required
                      autoComplete="off"
                      type="text"
                      name="user-salt-dispaly"
                      value={output.salt.length === 0 ? "N/A" : output.salt}
                      disabled
                    />
                  </InputGroup>
                </Form.Group>
              </Form>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Input Texts</th>
                    <th>
                      {operation === "encrypt"
                        ? "Encrypted Value"
                        : "Decrypted Value"}
                    </th>
                    <th>Copy Result</th>
                  </tr>
                </thead>
                <tbody>
                  {output?.items.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.input}</td>
                        <td>{item.result}</td>
                        <td>
                          <FontAwesomeIcon
                            icon={item.copied ? faClipboardCheck : faClipboard}
                            className={`fs-4 ${
                              item.copied ? "text-success" : ""
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigator.clipboard.writeText(item.result);
                              const tempOutputItems = output.items.map(
                                (element, ind) => {
                                  if (ind === index) {
                                    return {
                                      ...element,
                                      copied: true,
                                    };
                                  } else {
                                    return {
                                      ...element,
                                      copied: false,
                                    };
                                  }
                                }
                              );
                              const tempOutput = {
                                ...output,
                                items: tempOutputItems,
                              };
                              setOutput(tempOutput);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        )}
        {error.length !== 0 && (
          <Alert
            className="mt-3"
            variant="danger"
            onClose={() => setError("")}
            dismissible
          >
            {error}
          </Alert>
        )}
      </Col>
    </Row>
  );
};

export default AesHandler;
