import React, { useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import CustomTooltip from "../../../utilis/customTooltip/CustomTooltip";
import { encryptionTypes } from "../../../utilis/encryption/encryptionTypes";
import AesHandler from "./AesHandler";
import BcryptHandler from "./BcryptHandler";

const Encryption = () => {
  const [selectedEncryption, setSelectedEncryption] = useState(
    encryptionTypes.aes
  );

  return (
    <Container className="mt-3 mb-3">
      <Row>
        <Col>
          <Form>
            <Form.Group as={Col} className="mb-3">
              <InputGroup>
                <InputGroup.Text className="fw-bold pe-4">
                  What type of encryption you are looking for?
                </InputGroup.Text>
                <Dropdown>
                  <Dropdown.Toggle className="dropdown_headers">
                    {selectedEncryption.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(encryptionTypes).map((item) => {
                      return (
                        <Dropdown.Item
                          key={item}
                          onClick={() =>
                            setSelectedEncryption(encryptionTypes[item])
                          }
                        >
                          {encryptionTypes[item]?.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
                <CustomTooltip
                  button={
                    <FontAwesomeIcon icon={faCircleInfo} className="ms-2" />
                  }
                  message={selectedEncryption.description}
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      {selectedEncryption == encryptionTypes.aes && <AesHandler />}
      {selectedEncryption == encryptionTypes.bcrypt && <BcryptHandler />}
    </Container>
  );
};

export default Encryption;
