export const encryptionTypes = {
  aes: {
    name: "AES Encryption",
    description:
      "The Advanced Encryption Standard (AES) encryption is a bi-directional encryption algrithm, which means you can encrypt a data using a key and can easily decrypt the encrypted data using the same key. \
      In this program, we are using CryptoJS library that supports AES-128, AES-192, and AES-256 which is picked depending on the size of the key used for encryption.",
  },
  bcrypt: {
    name: "Bcrypt Encryption",
    description:
      "Bcrypt Encryption is a password hashing algorithm which is unidirectional, which means you can hash (encrypt) a password to get a hash value, but you cannot get original password value \
      from the hashed (encrypted) value. This is standard mechanism used to securely store users password so that even the application owner won't know the user's actual password text.",
  },
};
