import React from "react";
import Footer from "../../../utilis/footer/Footer";
import Titlebar from "../Titlebar";
import Encryption from "./Encryption";

const PublicEncryption = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Titlebar title="Secrets Encryption and Decryption" />
      <Encryption />
      <Footer />
    </div>
  );
};

export default PublicEncryption;
