import React from "react";
import "./Experience.scss";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { experiences } from "../../../../data";

const Experience = function () {
  const expCards = experiences?.map((data) => {
    let logoStyleLeftMargin = "0";

    if (data.id === 2 || data.id === 4) {
      logoStyleLeftMargin = "-4px";
    } else if (data.id === 5) {
      logoStyleLeftMargin = "10px";
    }

    return (
      <VerticalTimelineElement
        key={data.id}
        className="vertical-timeline-element--work"
        contentStyle={{ background: "white", color: "black" }}
        // eslint-disable-next-line prefer-template
        date={data.from + " - " + data.to}
        dateClassName="date"
        iconStyle={{ background: "white", color: "#fff" }}
        icon={
          <img
            className="org_logo"
            src={data.logo}
            alt="Org logo"
            style={{
              marginLeft: logoStyleLeftMargin,
              marginTop: data.id === 1 && "3px",
            }}
          />
        }
      >
        <h3 className="vertical-timeline-element-title">{data.position}</h3>
        <h5 className="vertical-timeline-element-subtitle">
          {data.company}, {data.address}
        </h5>
        <ul className="descriptin_list">
          {data.description?.map((item, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>{item}</li>
            );
          })}
        </ul>
      </VerticalTimelineElement>
    );
  });
  return (
    <div className="experience" id="experience">
      <div className="title_wrapper text-center pt-3 pb-3">
        <h3 className="title fw-bold text-light">Work Experiences</h3>
      </div>
      <div className="wrapper mt-5 mb-4">
        <VerticalTimeline
          className="exp_timeline"
          layout="2-columns"
          lineColor="white"
        >
          {expCards}
        </VerticalTimeline>
      </div>
    </div>
  );
};

export default Experience;
