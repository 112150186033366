import React, { useState } from "react";
import Editor from "@monaco-editor/react";
import { Button, Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import PointToTileFinder from "./PointToTileFinder";
import BBoxnToTileFinder from "./BBoxToTileFinder";

const TileFinder = () => {
  const [operation, setOperation] = useState("from-point");
  const [results, setResults] = useState(null);

  return (
    <Container className="mt-3">
      <Row className="mb-2">
        <Col>
          <Card>
            <Card.Header className="text-center fs-6 fw-bold">
              <Tabs
                defaultActiveKey="from-point"
                id="controlled-tab"
                className="mb-3"
                activeKey={operation}
                onSelect={(key) => setOperation(key)}
              >
                <Tab eventKey="from-point" title="From Point Geometry" />
                <Tab eventKey="from-polygon" title="From BBOX Geometry" />
              </Tabs>
            </Card.Header>
            <Card.Body>
              {operation === "from-point" ? (
                <PointToTileFinder results={results} setResults={setResults} />
              ) : (
                <BBoxnToTileFinder results={results} setResults={setResults} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          {results && (
            <Editor
              className="border border-2"
              height="65vh"
              defaultLanguage="json"
              value={JSON.stringify(results, null, 4)}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TileFinder;
