import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Titlebar = ({ title }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <Container className="justify-content-center text-light">
        <Row>
          <Col className="fw-bold fs-3">{title}</Col>
        </Row>
      </Container>
    </nav>
  );
};

export default Titlebar;
