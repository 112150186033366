import React from "react";
import "./Skills.scss";
import "aos/dist/aos.css";
import SkillCard from "./SkillCard";
import { skills } from "../../../../data";

const Skills = function () {
  return (
    <div className="skills" id="skills">
      <div className="title_wrapper text-center pt-3 pb-3">
        <h3 className="title fw-bold text-light">Technical Skills</h3>
      </div>
      <div className="container skill_cards_group_wrapper mt-5">
        <div className="row row_cards_wrapper justify-content-center m-0 p-0">
          <SkillCard
            title="Programming Languages"
            tagline="What programming languages do I know better?"
            simpleText={skills.expert.programming_lang.value}
            logos={skills.expert.programming_lang.logos}
          />
          <SkillCard
            title="Frameworks and Runtime Envirnments"
            tagline="There are several frameworks I got opportunity to work with."
            simpleText={skills.expert.frameworks_env.value}
            logos={skills.expert.frameworks_env.logos}
          />
          <SkillCard
            title="Tools and Infrastructure"
            tagline="Here are the advance programming tools and infrastructure that I have been using."
            data={skills.expert.tools_tech.value}
            logos={skills.expert.tools_tech.logos}
          />
          <SkillCard
            title="Programming IDE"
            tagline="Some of the best IDE that helped me from the begining."
            simpleText={skills.expert.ide.value}
            logos={skills.expert.ide.logos}
          />
          <SkillCard
            title="Operating System Exposure"
            tagline="I have owned and worked on several operating systems."
            simpleText={skills.expert.os.value}
            logos={skills.expert.os.logos}
          />
          <SkillCard
            title="Miscellaneous"
            tagline="There are some other technologies that I have used in my professional, academic and personal projects."
            simpleText={skills.expert.other.value}
            logos={skills.expert.other.logos}
          />
        </div>
      </div>
    </div>
  );
};

export default Skills;
