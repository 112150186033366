import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";

const Menubar = () => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary" bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand href="/geo-tooling">GeoTooling</Navbar.Brand>
        <Navbar.Toggle aria-controls="geotooling-navbar" />
        <Navbar.Collapse id="geotooling-navbar">
          <Nav className="ms-auto">
            <NavDropdown title="Map" id="map-nav-dropdown">
              <NavDropdown.Item href="#BBoxFinder">
                BBox Finder
              </NavDropdown.Item>
              <NavDropdown.Item href="#PolygonFinder">
                Polygon Finder
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link href="#Map">Map</Nav.Link> */}
            <Nav.Link href="#TileFinder">Tile Finder</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menubar;
