import React from "react";
import "./Home.scss";
import Typewriter from "typewriter-effect";
import { ChangingTextBlinker } from "react-changing-text-blinker";
import { introMsg, staticValues } from "../../../../data";

const Home = function () {
  const welcomeMsg = [
    "Welcome",
    "Welkom",
    "स्वागतम्",
    "Bienvenue",
    "willkommen",
    "欢迎",
    "Bienvenidas",
    "أهلا بك",
    "ようこそ",
    "환영",
  ];

  let introText = introMsg.greeting;
  introMsg.about.forEach((element) => {
    introText += element;
  });
  introText += introMsg.footer;
  return (
    <div className="home" id="home">
      <div className="container-fluid main-content text-light">
        <div className="row align-items-center">
          <div className="col main-content-top">
            <div className="row top-content">
              <div className="col text-center">
                <ChangingTextBlinker
                  className="welcomeMSg my-3"
                  stringArray={welcomeMsg}
                  delayTime={0.7}
                />
              </div>
            </div>
            <div className="row img_container">
              <div className="col d-flex justify-content-center">
                <div>
                  <img
                    src="/assests/sushant.png"
                    className="img-fluid mx-auto d-block sushant-img"
                    alt="Sushant_Image"
                  />
                </div>
              </div>
            </div>
            <div className="row quick-btn-container">
              <div className="col d-flex justify-content-center">
                <a
                  href={staticValues.resume_url}
                  className="btn-logo portfolio-btn m-4 text-success"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="far fa-address-book" />
                  &nbsp;Get Resume
                </a>
                <a
                  href="#contact"
                  className="btn-logo contact-btn m-4 text-success"
                >
                  <i className="far fa-envelope" />
                  &nbsp;Contact
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col main-content-bottom">
            <div className="row intro-text-content">
              <div className="col p-2 text-center">
                <Typewriter
                  options={{
                    strings: introText,
                    delay: 30,
                    autoStart: true,
                    loop: false,
                    wrapperClassName: "text-center",
                    cursorClassName: "far fa-hand-point-left fs-3",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
