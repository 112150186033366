import React, { useRef, useState, useEffect } from "react";
import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";
import "./map.scss";
import * as ol from "ol";
import { useGeographic } from "ol/proj";
import { Attribution, defaults as defaultControls } from "ol/control";

export const MapContext = new React.createContext();

const Map = ({ children, zoom, center }) => {
  useGeographic();

  const mapRef = useRef();
  const [map, setMap] = useState(null);

  // on component mount
  useEffect(() => {
    const attribution = new Attribution({
      collapsible: false,
    });

    let options = {
      view: new ol.View({ zoom, center }),
      layers: [],
      controls: defaultControls({ attribution: false }).extend([attribution]),
      overlays: [],
    };
    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, []);

  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className="ol-map">
        {children}
      </div>
    </MapContext.Provider>
  );
};

export default Map;
