import { useContext, useEffect } from "react";
import SearchNominatim from "ol-ext/control/SearchNominatim";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { MapContext } from "./Map";
import { transform } from "ol/proj";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Style, Icon, Fill, Stroke } from "ol/style";

const GeocoderService = () => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const markerSource = new VectorSource();

    const markerLayer = new VectorLayer({
      source: markerSource,
      style: new Style({
        image: new Icon({
          src: "/assests/location-dot-solid.svg",
          scale: 1.5,
          fill: new Fill({
            color: "#369",
          }),
          stroke: new Stroke({
            color: "#fff",
            width: 2,
          }),
        }),
      }),
    });

    map.addLayer(markerLayer);

    const searchNominatim = new SearchNominatim();

    searchNominatim.on("select", (event) => {
      const degree_coords = transform(
        event.coordinate,
        map.getView().getProjection(),
        "EPSG:4326"
      );

      const markerFeature = new Feature({
        geometry: new Point(degree_coords),
      });

      markerSource.clear();
      markerSource.addFeature(markerFeature);

      map.getView().animate({
        center: degree_coords,
        zoom: 15,
      });
    });

    map.addControl(searchNominatim);

    return () => {
      if (map) {
        map.removeLayer(markerLayer);
        map.removeControl(searchNominatim);
      }
    };
  }, [map]);

  return null;
};

export default GeocoderService;
